<template>
  <v-container class="app-respond-dialog-view" fluid>
    <div v-if="access.type === 'forbidden' && $context.user._isPublishVacancies">
      <access-warning />
    </div>
    <div v-else>
      <v-layout row wrap v-if="model">
        <v-flex xs12 v-if="model.fullResume && $context.user.isAdmin">
          <v-card>
            <v-alert :value="true" color="green">
              <h3>
                Вы обладаете правами администратора, показаны все данные резюме.
              </h3>
              <h3 v-if="model.resumeBuyers && model.resumeBuyers.length">
                Данное резюме куплено организациями: {{model.resumeBuyers.join(', ')}}.
              </h3>

              <v-btn @click="openContacts()" class="ml-0">
                Запустить задачу по открытию контактов
              </v-btn>
              <div v-if="$context.user.isAdmin">
                <a :href="`/#/resume/${model.id}`" style="color: white" target="_blank">
                  Постоянная ссылка на резюме
                </a>
              </div>
              <v-flex xs12 v-if="contactLoading || freelanceError">
                <div class="search-message" :class="{ 'search-message-error' : freelanceError }">
                  Загрузка контактов: {{ freelanceError }}
                </div>
                <v-progress-linear v-if="contactLoading" :indeterminate="true"/>
              </v-flex>
            </v-alert>
          </v-card>
        </v-flex>

        <v-flex xs12 v-if="$context.user.isAdmin">
          <v-card>
            <v-alert :value="true" color="white">
              <div class="app-respond-dialog-view__respond-info">
                <span class="mr-2">
                  {{ respond.user_name }}
                </span>
                <span class="mr-2">
                  {{ getTime(respond.ts) }}
                </span>
                <a :href="`/#/vacancies/list/${respond.vacancy_id}`" class="mr-2" style="text-decoration: none; color: black;" target="_blank">
                  {{ respond.vacancy_name }}
                </a>
                <span>
                  {{ respond.vacancy_organization_name }}
                </span>
              </div>
            </v-alert>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <v-card>
            <v-alert :value="true" color="blue">
              <div class="fude-columns">
                <div class="fude-columns__grow">
                  <div v-if="model.recruitingSelected">
                    <v-flex xs12>
                      <h3>Вакансия:</h3>
                    </v-flex>
                    <v-flex xs12 v-for="(item, index) in model.responds" :key="index" class="app-respond-dialog-view__vacancies-titles">
                      <a :href="`/#/vacancies/list/${item.vacancy_id}`" style="color: white" target="_blank">
                        {{ item.vacancy_name }} 
                      </a>
                      &nbsp;
                      <div>
                        - {{ item.respond_status_name }}
                      </div>
                    </v-flex>
                  </div>
                  <div v-if="model.selected">
                    <br>
                    <v-flex xs12>
                      <h3>
                        Добавлено в папки:
                      </h3>
                    </v-flex>
                    <v-flex xs12 v-for="item in model.vacancies" :key="item.vacancy_id" class="ml-4">
                      <a :href="`/#/recruiting/${item.vacancy_id}`" style="color: white" target="_blank">
                        {{ item.vacancy_name }}
                      </a>
                      <span>-</span>
                      <span>{{ item.status_name }}</span>
                    </v-flex>
                  </div>
                  <div class="mt-3">
                    <span class="resume-comments" style="text-decoration: underline;" @click="openComments">
                      Комментарии
                    </span>
                    <span class="resume-comments" @click="openComments">
                      ({{ model.commentsCount || 0 }})
                    </span>
                  </div>
                </div>
                <v-layout class="fude-columns__shrink">
                  <v-flex xs12>
                    <v-icon 
                      x-large 
                      @click="selectResume()" 
                      class="app-respond-dialog-view__icon-commands"
                      title="Добавить в папку"
                    >
                      mdi-bookmark-outline
                    </v-icon>
                    <v-icon 
                      x-large 
                      v-if="downloadHref && model.fullResume" 
                      @click="downloadResume()" 
                      class="app-respond-dialog-view__icon-commands"
                      title="Скачать"
                    >
                      mdi-download
                    </v-icon>
                  </v-flex>
                </v-layout>
              </div>
            </v-alert>
          </v-card>
        </v-flex>

        <v-flex xs12 v-if="!model.fullResume && !$context.user.isOnlySearch">
          <v-card v-if="model.recruitingSelected">
            <v-alert :value="model.required.length" color="red">
              <h4>
                Внимание! В полной версии резюме отсутствуют данные: {{model.required.join(', ')}}.
              </h4>
            </v-alert>
            <v-alert :value="!model.isAllowedRegion" color="red">
              <h4>
                Контактные данные кандидата недоступны в связи с регионом: {{model.location ? model.location.region_name : 'Регион не определен'}}.
              </h4>
            </v-alert>
            <v-alert :value="model.nonRequired.length" color="green">
              <h4>
                В данном резюме указаны дополнительные контакты: {{model.nonRequired.join(', ')}}.
              </h4>
            </v-alert>

            <div class="alert-border">
              <h4 v-if="model.tariff" class="resume-message">
                Вы можете открыть полное резюме в рамках пакета "{{model.tariff}}".
              </h4>

              <h4 v-if="!model.tariff" class="resume-message">
                Вы сможете открыть полное резюме после покупки пакета контактов.
                Обратитесь к вашему менеджеру.
              </h4>

              <h4 v-show="model.tariff" class="resume-message" :class="{ 'resume-message--alert' : model.resumesBalance <= 0 }">
                Остаток резюме по пакету: {{model.resumesBalance}}.
              </h4>

              <h4 
                v-show="userLimits && userLimits.limit_current != null" 
                class="resume-message" 
                :class="{ 'resume-message--alert': userLimits.limit_current <= 0 }"
              >
                Ваш персональный лимит на покупку резюме: {{userLimits.limit_max}} резюме. Осталось: {{userLimits.limit_current}} резюме.
              </h4>

              <v-btn 
                v-if="!$context.user.isTestUser" 
                @click="buyResume()"
                :disabled="contactLoading || (model.resumesBalance <= 0) || (userLimits && userLimits.limit_current <= 0) || !model.tariff"
                class="ml-0"
              >
                Открыть полное резюме
              </v-btn>

              <v-icon v-if="model.has_contacts != null" color="#2196F3" style="float: right; margin-top: 12px;">
                {{ model.has_contacts ? 'mdi-rhombus' : 'mdi-rhombus-outline' }}
              </v-icon>

              <v-flex xs12 v-if="contactLoading || freelanceError">
                <div class="search-message" :class="{ 'search-message-error' : freelanceError }">
                  Загрузка контактов: {{ freelanceError }}
                </div>
                <v-progress-linear v-if="contactLoading" :indeterminate="true"/>
              </v-flex>
            </div>
          </v-card>

          <v-card v-if="!model.recruitingSelected">
            <v-alert :value="model.required.length" color="red">
              <h4>
                Внимание! В полной версии резюме отсутствуют данные: {{model.required.join(', ')}}.
              </h4>
            </v-alert>
            <v-alert :value="!model.isAllowedRegion" color="red">
              <h4>
                Контактные данные кандидата недоступны в связи с регионом: {{model.location ? model.location.region_name : 'Регион не определен'}}.
              </h4>
            </v-alert>
            <v-alert :value="model.nonRequired.length" color="green">
              <h4>
                В данном резюме указаны дополнительные контакты: {{model.nonRequired.join(', ')}}.
              </h4>
            </v-alert>

            <div class="alert-border">
              <h4 v-if="model.tariff" class="resume-message">
                Вы можете открыть полное резюме в рамках пакета "{{model.tariff}}".
              </h4>

              <h4 v-if="!model.tariff" class="resume-message">
                Вы сможете открыть полное резюме после покупки пакета контактов.
                Обратитесь к вашему менеджеру.
              </h4>

              <h4 v-show="model.tariff" class="resume-message" :class="{ 'resume-message--alert' : model.resumesBalance <= 0 }">
                Остаток резюме по пакету: {{model.resumesBalance}}.
              </h4>

              <h4 
                v-show="userLimits && userLimits.limit_current != null" 
                class="resume-message" 
                :class="{ 'resume-message--alert': userLimits.limit_current <= 0 }"
              >
                Ваш персональный лимит на покупку резюме: {{userLimits.limit_max}} резюме. Осталось: {{userLimits.limit_current}} резюме.
              </h4>

              <v-btn 
                v-if="!$context.user.isTestUser && !model.exchange_system_member" 
                @click="buyResume()"
                :disabled="contactLoading || !model.isAllowedRegion || (model.resumesBalance <= 0) || (userLimits && userLimits.limit_current <= 0)"
                class="ml-0"
              >
                Открыть полное резюме
              </v-btn>

              <v-btn 
                v-if="!$context.user.isTestUser && model.exchange_system_member" 
                @click="buyResume()"
                :disabled="contactLoading || !model.isAllowedRegion || (model.resumesBalance <= 0) || (userLimits && userLimits.limit_current <= 0)"
                class="ml-0"
              >
                Открыть полное резюме за деньги
              </v-btn>

              <v-btn 
                v-if="!$context.user.isTestUser && model.exchange_system_member" 
                @click="buyResumeByChips()"
                :disabled="contactLoading || !model.isAllowedRegion || !model.hasChips || !this.hasChips"
                class="ml-0"
              >
                Открыть полное резюме за фишки
              </v-btn>

              <v-btn v-if="$context.user.isTestUser" @click="register()" class="ml-0">
                Открыть полное резюме
              </v-btn>

              <v-icon v-if="model.has_contacts != null" color="#2196F3" style="float: right; margin-top: 12px;">
                {{ model.has_contacts ? 'mdi-rhombus' : 'mdi-rhombus-outline' }}
              </v-icon>

              <v-flex xs12 v-if="!this.hasChips" class="search-message search-message-error">
                Недостаточно фишек
              </v-flex>

              <v-flex xs12 v-if="contactLoading || freelanceError">
                <div class="search-message" :class="{ 'search-message-error' : freelanceError }">
                  Загрузка контактов: {{ freelanceError }}
                </div>
                <v-progress-linear v-if="contactLoading" :indeterminate="true"/>
              </v-flex>
            </div>
          </v-card>
        </v-flex>

        <v-flex xs12 v-if="model" class="app-respond-duplicate-state" >
          <v-icon v-if="model.duplicate_state === 'unverified'">
            mdi-loading mdi-spin
          </v-icon>
          <h3 v-if="model.duplicate_state === 'verified'" class="green--text">
            Резюме проверено — дубликатов нет
          </h3>
          <h3 v-else-if="model.duplicate_state === 'paired'" class="red--text">
            Резюме на проверке у модератора
          </h3>
          <h3 v-else class="red--text">
            Резюме проверяется на дубликаты
          </h3>
        </v-flex>

        <v-flex v-if="error" xs12>
          <v-card>
            <v-alert :value="true" color="red">
              {{error.error}}
            </v-alert>
          </v-card>
        </v-flex>

        <v-flex class="resume-view" xs12>
          <app-resume-info-view :model="model"/>
          <app-resume-contacts-view v-if="model.contacts" :model="model"/>
          <app-resume-location-view :model="model"/>
          <app-props-view :source="model.moving" :items="moving_properties"/>
          <app-resume-moving-regions-view :model="model"/>
          <app-props-view :source="model.trip" :items="trip_properties"/>
          <app-resume-citizenships-view :model="model"/>
          <app-resume-permits-view :model="model"/>
          <app-resume-positions-view :model="model"/>
          <app-resume-experiences-view :model="model"/>
          <app-resume-recommendations-view :model="model"/>
          <app-resume-tags-view :model="model"/>
          <app-resume-driving-view :model="model"/>
          <app-resume-educations-view :model="model"/>
          <app-resume-language-view :model="model"/>
          <app-resume-about-view :model="model"/>
        </v-flex>

      </v-layout>
      <v-layout v-if="!model">
        <v-flex v-if="!error" class="resume-view" xs12>
          <v-progress-linear :indeterminate="true"/>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';

import AppResumeInfoView from '../../../resume/info/resume-info-view.vue';
import AppResumeLocationView from '../../../resume/location/resume-location-view.vue';
import AppResumeContactsView from '../../../resume/contact/resume-contacts-view.vue';
import AppResumePermitsView from '../../../resume/permits/resume-permits-view.vue';
import AppResumeCitizenshipsView from '../../../resume/citizenships/resume-citizenships-view.vue';
import AppResumePositionsView from '../../../resume/positions/resume-positions-view.vue';
import AppResumeExperiencesView from '../../../resume/experience/resume-experiences-view.vue';
import AppResumeEducationsView from '../../../resume/education/resume-educations-view.vue';
import AppResumeLanguageView from '../../../resume/language/resume-language-view.vue';
import AppResumeTagsView from '../../../resume/tags/resume-tags-view.vue';
import AppResumeDrivingView from '../../../resume/driving/resume-driving-view.vue';
import AppResumeRecommendationsView from '../../../resume/recommendations/resume-recommendations-view.vue';
import AppResumeMovingRegionsView from '../../../resume/moving-regions/resume-moving-regions-view.vue';
import AppResumeAboutView from '../../../resume/about/resume-about-view.vue';
import AppPropsView from '../../../common/props-view.vue';
import ChoosePackageDialog from '../../../resume/choose-package-dialog.vue';
import DuplicateDialog from '../../../resume/duplicate-dialog.vue';
import SourcesDialog from '../../../resume/sources-dialog.vue';
import AcceptDialog from '../../../resume/accept-dialog.vue';
import DivideDialog from '../../../resume/divide-dialog.vue';
import RegisterDialog from '../../../register/register-dialog.vue';
import EditPackageDialog from '../../../vacancies/edit-package-dialog.vue';
import AccessWarning from '../../../access/access-warning.vue';
import CommentListDialog from '../../../resume/comment-list-dialog.vue';

const CONNECT = 'connect';
const TYPE = 'type';
const TYPE_USER = 'user';
const RESUME_CONTACT_OPEN = 'resume:contact_open';
const RESUME_OPEN = 'resume:open';
const DISCONNECT = 'disconnect';
const RESUME_DUPLICATE_STATE_CHANGED = 'resume:duplicate_state_changed';

export default {
  name: 'app-respond-dialog-view',

  components: {
    AppResumeMovingRegionsView,
    AppResumeEducationsView,
    AppResumeDrivingView,
    AppResumeTagsView,
    AppResumeLanguageView,
    AppResumeLocationView,
    AppResumeContactsView,
    AppResumePermitsView,
    AppResumeCitizenshipsView,
    AppResumeExperiencesView,
    AppResumeRecommendationsView,
    AppResumePositionsView,
    AppResumeInfoView,
    AppResumeAboutView,
    AppPropsView,
    AccessWarning,
    CommentListDialog
  },

  data() {
    return {
      vacancy: null,
      respond: null,
      model: null,
      organization: window.localStorage['organization'],
      page: 0,
      items_per_page: 50,
      sort_order: 'name',
      error: null,
      contactLoading: false,
      freelanceError: null,
      isConnected: false,
      hasChips: true,
      bought_type: null,
      allPackages: [],
      resume_state: null,
      resume_state_has_duplicates: null,
      stateTimer: null,
      stateTimerInterval: 60000,
			access: {},
      userLimits: {},
    }
  },

  computed: {
    downloadHref() {
      return (this.model && this.model.id)
        ? `/api/v1/main/resumes/${this.model.id}/download`
        : null;
    },
    moving_properties() {
      return [
        {
          id: 'moving',
          name: 'Готовность к переездам',
          value: this.model.moving && this.model.moving.moving_name
        }
      ];
    },
    moving_regions_properties() {
      return [
        {
          id: 'moving_regions',
          name: 'Регионы переезда',
          value: this.model.moving_regions && this.model.moving_regions.region_name
        }
      ];
    },
    trip_properties() {
      return [
        {
          id: 'trip',
          name: 'Готовность к командировкам',
          value: this.model.trip && this.model.trip.trip_name
        }
      ];
    },
    citizenship_properties() {
      return [
        {
          id: 'citizenship',
          name: 'Гражданство',
          value: this.model.citizenship
        }
      ];
    }
  },

  sockets: {
    events: {
      [CONNECT]() {
        this.isConnected = true;
        this.$sockets.$emit(TYPE, TYPE_USER);
      },
      [RESUME_DUPLICATE_STATE_CHANGED]({ id, state, hasDuplicates}) {
        let current_id = this.model 
          ? this.model.id 
          : this.$route.params.source 
          ? this.$route.params.source 
          : this.respond.person_id;
        if(this.model) {
          if (id && state && id === this.model.id) {
            if(this.stateTimer) {
              clearTimeout(this.stateTimer);
              this.stateTimer = null;
            }
            this.model.duplicate_state = state;
            if(state === 'verified' && hasDuplicates === true && !this.contactLoading) {
              window.location.reload();
            }
          }
        } else {
          //Если событие пришло до того как загрузилось резюме из БД
          if (id && state && id === current_id) {
            this.resume_state_has_duplicates = hasDuplicates;
            this.resume_state = state;
          }
        }
      },
      [RESUME_CONTACT_OPEN]({ data, error }) {
        if (data && data.id === this.model.id && !error) {
          let params = {
            id: this.respond.person_id,
            buy: true,
            organization_id: this.organization,
            bought_type: this.bought_type,
            task_id: data.task_id,
            tariff_id: data.tariff_id,
            respond_id: this.respond.id
          };
          if (data.bought_type !== 'admin') {
            this.$http.post(`/api/v1/main/resumes/buy`, params).then((res) => {
              if (res.data.error) {
                this.error = res.data;
              } else {
                this.model = res.data;
              }
            });
          } else {
            window.location.reload();
          }
          this.contactLoading = false;
        } else {
          this.freelanceError = error  || 'Что-то пошло не так, обратитесь к вашему менеджеру';
          this.contactLoading = false;
        }
      },
      [RESUME_OPEN]({ data, error }) {
        let params = {
          id: this.respond.person_id,
          buy: true,
          organization_id: this.organization,
          bought_type: this.bought_type,
          tariff_id: data && data.tariff_id,
          respond_id: this.respond.id
        };
        this.$http.post(`/api/v1/main/resumes/buy`, params).then((res) => {
          if (res.data.error) {
            this.error = res.data;
          } else {
            this.model = res.data;
          }
          this.contactLoading = false;
        });
      },
      [DISCONNECT]() {
        this.isConnected = false;
      }
    }
  },

  created() {
    this.$context.subTitle = 'Просмотр резюме';
  },

  async mounted() {
    await this.loadAccess();
    await this.getRespond();
    await this.loadVacancy();
    await this.setRepondStatus();
    let isAdmin = this.$context.user.isInRole('admin');
    this.$context.subTitle = 'Просмотр резюме';

    if (!this.$core.tools.isUUID(this.$route.params.id)) {
      this.error = {
        error: 'Неверная ссылка на резюме'
      };
      return;
    }

    if (isAdmin) {
      this.$context.topActions = [];
       if (!this.$route.params.source && await this.hasSeveralSources()) {
         this.$context.topActions.push({
           title: 'Источники',
           handler: () => this.showSources()
         });
       }
      this.$context.topActions.push(
        {
          title: 'Данные для дубликатов',
          handler: () => this.showDuplicateData()
        },{
          title: 'Редактировать',
          handler: () => this.edit()
        }
      );
    }
    await this.getResume();
    if (this.model.duplicate_state === 'unverified') {
      if (this.resume_state) {
        //Проставляем статус, если он пришел из сокета до загрузки резюме
        this.model.duplicate_state = this.resume_state;
        if(this.model.duplicate_state === 'verified' && this.resume_state_has_duplicates && !this.contactLoading) {
          window.location.reload();
        }
      } else {
        //Запускаем таймер ожидания статуса резюме из сокета
        this.stateTimer = setTimeout(this.stateTimerExpired, this.stateTimerInterval);
      }
    }
    await this.getUserLimits();
  },

  beforeDestroy() {
    this.$context.topActions = null;
  },

  methods: {
    openComments() {
      this.$dialog.$show({
        title: 'Комментарии к резюме',
        component: CommentListDialog,
        model: this.model,
        actions: [
          {
            result: 'OK',
            name: this.$t('fude.dialog.actions.ok'),
            handle: () => {
              this.getResume();
            }
          }
        ],
        onShow: () => {
          this.dialog = true;
        },
        onHide: () => {
          this.dialog = false;
        }
      });
    },
    async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
    async getPackages() {
      let res = await this.$http.get('/api/v1/main/vacancies/packages', {
        headers: {
          organization: this.organization
        }
      });
      this.allPackages = res.data;
    },
    async hasSeveralSources() {
      return (await this.$http.get('/api/v1/main/resumes/' + this.respond.person_id + '/has_several_sources')).data;
    },
    async getRespond() {
      this.respond = (await this.$http.get(`/api/v1/recruiting/respond/${this.$route.params.id}`)).data;
    },
    async getResume() {
      const headers = {
        organization: this.organization
      };
      this.model = (await this.$http.get('/api/v1/main/resumes/'+ this.respond.person_id + '/resume', { headers })).data;
    },
    register() {
      let model = {
        name: '',
        phone: '',
        email: '',
        social: '',
        contacts: {
          whatsapp: false,
          telegram: false,
          facebook: false,
          vk: false
        },
        promo: '',
        warning: 'Полное резюме доступно только после регистрации'
      };
      this.$dialog.$show({
        title: 'Регистрация',
        component: RegisterDialog,
        model,
        actions: [{
          result: 'OK',
          name: 'Отправить заявку',
          color: 'primary',
          handle: async () => {
            try {
              await this.$http.post('/api/v1/main/registers', model);
              this.$dialog.$info('Ваша заявка принята. В ближайшее время мы с вами свяжемся.');
            } catch (ex) {
              console.log(ex);
              this.$dialog.$info('Что-то пошло не так. Обратитесь к администратору');
            }
          }
        },{
          result: 'CANCEL',
          name: this.$t('fude.dialog.actions.cancel'),
        }]
      })
    },
    async showDuplicateData() {
      let table = {
        rows: []
      };

      let params = {
        id: this.respond.person_id
      };
      await this.$http.post(`/api/v1/main/resumes/duplicate_data`, params).then((res) => {
        table.rows = res.data;
      });

      this.$dialog.$show({
        component: DuplicateDialog,
        title: 'Данные для поиска дубликатов',
        model: table,
        actions: [{
          result: 'CANCEL',
          name: this.$t('fude.dialog.actions.cancel')
        }]
      })
    },
    async showSources() {
      let table = {
        rows: []
      };

      let params = {
        id: this.respond.person_id
      };
      await this.$http.post(`/api/v1/main/resumes/sources`, params).then((res) => {
        table.rows = res.data.content;
        table.bought = res.data.bought;
        table.added = res.data.added;
      });

      this.$dialog.$show({
        component: SourcesDialog,
        title: 'Источники',
        model: table,
        actions: [
          {
            result: 'verify',
            name: 'ПОДТВЕРДИТЬ ВСЕ',
            handle: async () => {
              await this.$http.get(`/api/v1/main/resumes/${this.model.id}/verify`);
            }
          },
          {
            result: 'unverify',
            name: 'ОТМЕНИТЬ ПОДТВЕРЖДЕНИЕ',
            handle: async () => {
              await this.$http.get(`/api/v1/main/resumes/${this.model.id}/unverify`);
            }
          },
          {
            result: 'OK',
            name: this.$t('fude.dialog.actions.ok'),
            handle: async () => {
              params.rows = table.rows.filter(i => i.check);

              this.$dialog.$show({
                component: AcceptDialog,
                title: 'Создать отдельное резюме по слоям?',
                model: params,
                actions: [
                  {
                    result: 'OK',
                    name: this.$t('fude.dialog.actions.ok'),
                    handle: async () => {
                      params.ids = params.rows.map(i => i.id);
                      await this.$http.post(`/api/v1/main/resumes/divide`, params).then((res) => {
                        if (res.data) {
                          window.open(`${location.origin}/#/resume/${res.data}`);
                        }
                      });
                    }
                  },
                  {
                    result: 'CANCEL',
                    name: this.$t('fude.dialog.actions.cancel')
                  }
                ]
              })
            }
          },
          {
            result: 'divide_all',
            name: 'РАЗДЕЛИТЬ ВСЕ',
            handle: async () => {
              params.rows = table.rows.sort((i1, i2) => i1.ts < i2.ts ? 1 : -1);

              this.$dialog.$show({
                component: AcceptDialog,
                title: 'Разделить все слои по отдельности?',
                model: params,
                actions: [
                  {
                    result: 'OK',
                    name: this.$t('fude.dialog.actions.ok'),
                    handle: async () => {
                      for (let i = 1; i < params.rows.length; i++) {
                        params.ids = [params.rows[i].id];
                        await this.$http.post(`/api/v1/main/resumes/divide`, params).then((res) => {
                          if (res.data) {
                            console.log("new resume " + res.data);
                          }
                        });
                      }
                    }
                  },
                  {
                    result: 'CANCEL',
                    name: this.$t('fude.dialog.actions.cancel')
                  }
                ]
              })
            }
          },
          {
            result: 'CANCEL',
            name: this.$t('fude.dialog.actions.cancel')
          }
        ]
      })
    },
    async divideResume() {
      let params = {
        id: this.$route.params.source,
        person_id: this.respond.person_id
      };

      this.$dialog.$show({
        component: DivideDialog,
        title: 'Разделение резюме',
        actions: [
          {
            result: 'OK',
            name: this.$t('fude.dialog.actions.ok'),
            handle: async () => {
              await this.$http.post(`/api/v1/main/resumes/divide`, params).then((res) => {
                if (res.data) {
                  this.$navigate(`/#/resume/${res.data}`);
                }
              });
            }
          },
          {
            result: 'CANCEL',
            name: this.$t('fude.dialog.actions.cancel')
          }
        ]
      })
    },
    edit() {
      // this.$navigate(`/#/resume/edit/${this.model.id}`);
    },
    async buyResume() {
      await this.getResumeBalance();
      if (parseInt(this.model.resumesBalance) <= 0) {
        await this.getResume();
        return;
      }
      await this.getUserLimits();
      if (this.userLimits && parseInt(this.userLimits.limit_current) <= 0) return;
      this.bought_type = 'cash';

      let res = (await this.$http.get(`/api/v1/main/resumes/${this.respond.person_id}/need_contact_task`, {
        headers: {
          organization: this.organization,
          tariff: this.model.tariff_id
        }
      })).data;

      switch (res) {
        case 'contact':
          this.contactLoading = true;
          this.freelanceError = null;
          this.$sockets.$emit(RESUME_CONTACT_OPEN, {
            sid: this.model.sid,
            organization_id: this.organization,
            person_id: this.respond.person_id,
            bought_type: this.bought_type,
            tariff_id: this.model.tariff_id
          });
          break;
        case 'view':
          this.contactLoading = true;
          this.freelanceError = null;
          this.$sockets.$emit(RESUME_OPEN, {
            sid: this.model.sid,
            organization_id: this.organization,
            person_id: this.respond.person_id,
            bought_type: this.bought_type,
            tariff_id: this.model.tariff_id
          });
          break;
        default:
          this.contactLoading = true;
          let params = {
            id: this.respond.person_id,
            buy: true,
            organization_id: this.organization,
            bought_type: this.bought_type,
            tariff_id: this.model.tariff_id,
            respond_id: this.respond.id
          };
          res = (await this.$http.post(`/api/v1/main/resumes/buy`, params)).data;
          this.contactLoading = false;
          if (res.error) {
            this.error = res;
          } else {
            this.model = res;
          }
          break;
      }
    },
    async openContacts() {
      this.contactLoading = true;
      this.freelanceError = null;
      this.$sockets.$emit(RESUME_CONTACT_OPEN, {
        sid: this.model.sid,
        organization_id: this.organization,
        person_id: this.respond.person_id,
        bought_type: 'admin',
        tariff_id: this.model.tariff_id
      });
    },
    async buyResumeByChips() {
      await this.getUserLimits();
      if (this.userLimits && parseInt(this.userLimits.limit_current) <= 0) return;
      this.bought_type = 'chips';

      let res = (await this.$http.get(`/api/v1/main/resumes/${this.respond.person_id}/need_contact_task`, {
        headers: {
          organization: this.organization,
          tariff: this.model.tariff_id
        }
      })).data;
      let needContactTask = res === 'contact';

      this.hasChips = (await this.$http.get(`/api/v1/main/resumes/${this.organization}/has_chips`)).data;

      if (!this.hasChips) {
        return;
      }

      if (needContactTask) {
        this.contactLoading = true;
        this.freelanceError = null;
        this.$sockets.$emit(RESUME_CONTACT_OPEN, {
          sid: this.model.sid,
          organization_id: this.organization,
          person_id: this.respond.person_id,
          bought_type: this.bought_type
        });
      } else {
        let params = {
          id: this.respond.person_id,
          buy: true,
          organization_id: this.organization,
          bought_type: this.bought_type,
          respond_id: this.respond.id
        };

        res = (await this.$http.post(`/api/v1/main/resumes/buy`, params)).data;
        if (res.error) {
          this.error = res;
        } else {
          this.model = res;
        }
      }
    },

    async selectResume() {
      await this.getPackages();
      let model = {
        id: this.model.id,
        usedVacancies: this.model.usedVacancies,
        allPackages: this.allPackages,
        respond_id: this.respond.id
      };

      this.$dialog.$show({
        component: ChoosePackageDialog,
        title: 'Добавление резюме в папку',
        model: model,
        actions: [
          {
            result: 'create',
            name: 'СОЗДАТЬ ПАПКУ',
            left: true,
            handle: async () => {

              let model = {
                name: null,
                person_id: this.model.id,
                organization_id: this.organization,
                respond_id: this.respond.id
              };

              this.$dialog.$show({
                component: EditPackageDialog,
                title: 'Создать новую папку и добавить резюме',
                model: model,
                actions: [{
                  result: 'OK',
                  name: this.$t('fude.dialog.actions.ok'),
                  handle: async () => {
                    await this.$http.post('/api/v1/main/vacancies/create_package', model);
                    await this.$http.get(`/api/v1/main/resumes/${this.model.id}/resume`, {
                      headers: {
                        organization: this.organization,
                      }
                    }).then((res) => {
                      if (res.data.error) {
                        this.error = res.data;
                      } else {
                        this.model = res.data;
                      }
                    });
                  }
                },
                  {
                    result: 'CANCEL',
                    name: this.$t('fude.dialog.actions.cancel')
                  }]
              })
            }
          },
          {
            result: 'OK',
            name: this.$t('fude.dialog.actions.ok'),
            handle: async () => {
              model.organization_id = this.organization;
              await this.$http.post(`/api/v1/main/resumes/select`, model);
              await this.$http.get(`/api/v1/main/resumes/${this.model.id}/resume`, {
                headers: {
                  organization: this.organization,
                }
              }).then((res) => {
                if (res.data.error) {
                  this.error = res.data;
                } else {
                  this.model = res.data;
                }
              });
            }
          },
          {
            result: 'CANCEL',
            name: this.$t('fude.dialog.actions.cancel')
          }
        ]
      })
    },

    async getUserLimits() {
      this.userLimits = (await this.$http.get(`/api/v1/main/user_limits/${this.organization}/${this.$context.user.id}`)).data;
    },

    async getResumeBalance() {
      this.model.resumesBalance = (await this.$http.post(`/api/v1/main/resumes/balance`, { 
        tariff_id: this.model.tariff_id, 
        organization_id: window.localStorage['organization']
      })).data;
    },

    async loadVacancy() {
      this.vacancy = (await this.$http.get(`/api/v1/recruiting/vacancy/${this.respond.vacancy_id}`)).data;
    },

    async setRepondStatus() {
      if (this.respond && this.respond.respond_status_id === 'c6cc4956-5037-496b-b409-65f7843656c2') {
        if (this.respond.append_type === 'researcher') {
          if (parseInt(this.vacancy.used_slots) > 0) {
            this.vacancy.used_slots = parseInt(this.vacancy.used_slots) - 1;
            await this.$http.put(`/api/v1/recruiting/vacancy/${this.vacancy.id}`, this.vacancy);
          }
        }

        let model = {
          id: this.respond.id,
          status_id: '66ad2154-3c8d-4df6-96f3-0bc1dac35a0e',
        };

        this.respond.respond_status_id = '66ad2154-3c8d-4df6-96f3-0bc1dac35a0e';
        await this.$http.put(`/api/v1/recruiting/respond/${this.respond.id}`, model);
        await this.$http.put(`/api/v1/organizations/${this.organization}`);
      }
    },

    downloadResume() {
      try {
        const link = document.createElement('a');
        link.target = "_blank";
        link.href = this.downloadHref;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.log('При скачивании произошла ошибка', error);
      }
    },

    async stateTimerExpired() {
      //Если за требуемое время статус проверки на дубликаты так и не пришел, то принудительно запрашиваем его
      if(this.stateTimer) {
        clearTimeout(this.stateTimer);
        this.stateTimer = null;
      }
      try {
        let res = (await this.$http.get('/api/v1/main/resumes/'+ this.model.id + '/resume', {
          headers: {
            organization: window.localStorage['organization']
          }
        })).data;
        if(res) {
          this.model.duplicate_state = res.duplicate_state;
          //если резюме стало дубликатом и на данный момент не в процессе покупки, то обновляем страничку, чтоб актуализировать данные
          if(this.model.duplicate_state === 'verified' && res.id !== this.model.id  && !this.contactLoading)
            window.location.reload();
        }
      } catch (err) {
        console.log(`Error while getting duplicate state from api: ${err}`);
      }
    },

    getTime(timestamp) {
      return moment(parseInt(timestamp)).utc().format('DD.MM.YYYY HH:mm');
    }
  }
}
</script>

<style lang="less">

.fude-columns {
  display: flex;

  .fude-columns__grow {
    display: inline-flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
  }

  .fude-columns__shrink {
    display: inline-flex;
    flex-grow: 0;
  }
}

.alert-border{
  border-top-color: currentcolor !important;
  border-top-style: solid !important;
  border-top-width: 5px !important;
  border-right-color: currentcolor !important;
  border-right-style: solid !important;
  border-right-width: 5px !important;
  border-bottom-color: currentcolor !important;
  border-bottom-style: solid !important;
  border-bottom-width: 5px !important;
  border-left-color: currentcolor !important;
  border-left-style: solid !important;
  border-left-width: 5px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
  color: #ff9800 !important;
  caret-color: #ff9800 !important;

  margin: 4px auto;
  padding: 16px;
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-delay: 0s;
}

.app-respond-dialog-view {
  padding-top: 0;

  .app-respond-dialog-view__respond-info {
    color: black;
  }

  .app-respond-duplicate-state {
    margin: 8px 0 10px 0px;
    
    .mdi-spin {
      float: left;
      padding-right: 8px;
      color: red;
    }

    .mdi-spin:before {
      animation: mdi-spin 1s infinite linear!important;
    }
  }

  .v-chip {
    .v-chip__content {
      padding: 2px 8px!important;
    }
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .resume-view {
    h3 {
      font-size: 15px;
      word-break: break-word;
    }
  }

  .resume-view > .v-card {
    margin-bottom: 1px;
  }

  .search-message {
    margin: 8px;
    font-style: italic;
    font-weight: bold;
    color: #2196F3;
  }

  .search-message.search-message-error {
    color: #F44336;
  }

  .resume-message {
    color: black;
    font-size: 15px;
    &.resume-message--alert {
      color: red;
    }
  }

  .app-respond-dialog-view__stop-list-info {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  .app-respond-dialog-view__vacancies-titles {
    display: flex;
  }

  .app-respond-dialog-view__icon-commands {
    margin-right: 5px;
    cursor: pointer;
    color: white;
  }

  .resume-comments {
    cursor: pointer;
    color: white;
  }
}
</style>
