<template>
  <div class="app-registration-form">
    <div class="app-registration-form-title">
      <h1>
        Регистрация
      </h1>
    </div>
    <div class="app-registration-form-main">
      <div class="vacancy__title">
        <h4>
          ФИО
        </h4>
        <v-text-field
          v-model.trim="model.name" 
          placeholder="Введите ваше ФИО"
          class="title__input"
          outline
          clearable
          height="42"
          :error-messages="errorMessages.name"
          maxlength="100"
        />
      </div>
      <div class="vacancy__title">
        <h4>
          Телефон
        </h4>
        <field-phone-number
          v-model.trim="model.phone" 
          class="title__input"
          :mask="currentPhoneHelper.mask"
          :mask-checker="currentPhoneHelper.maskChecker"
          :symbols-to-remove-from-mask="currentPhoneHelper.symbolsToRemove"
          :placeholder="currentPhoneHelper.placeholder"
          :error-messages="errorMessages.phone"
        />
      </div>
      <div class="vacancy__title">
        <h4>
          E-mail
        </h4>
        <v-text-field
          v-model.trim="model.email" 
          placeholder="Введите ваш email"
          class="title__input"
          outline
          clearable
          height="42"
          :error-messages="errorMessages.email"
          maxlength="100"
        />
      </div>
      <div v-if="employer" class="vacancy__title">
        <h4>
          Название компании
        </h4>
        <v-text-field
          v-model.trim="model.organization_name" 
          placeholder="Введите название вашей компании"
          class="title__input"
          outline
          clearable
          height="42"
          :error-messages="errorMessages.organization_name"
          @change="checkWhitespaces"
          maxlength="150"
        />
      </div>
      <div class="vacancy__title">
        <h4>
          Ссылка на сайт или соцсеть
        </h4>
        <v-text-field
          v-model.trim="model.link" 
          placeholder="Вставьте ссылку на ваш сайт или соцсеть"
          class="title__input"
          outline
          clearable
          height="42"
          :error-messages="errorMessages.link"
          maxlength="150"
        />
      </div>
      <div class="vacancy__title mb-5">
        <h4>
          Прошу связаться со мной
        </h4>
        <v-checkbox 
          v-model="model.whatsapp"
          label="Whatsapp"
          hide-details
          color="primary"
        />
        <v-checkbox 
          v-model="model.telegram" 
          label="Telegram"
          hide-details
          color="primary"
        />
      </div>
    </div>
    <div class="align-self-center">
      <v-btn
        color="primary"
        @click="check()"
      >
        <span>
          Зарегистрироваться
        </span>
      </v-btn>
    </div>
    <div class="v-text-field__details mt-2 mb-2">
      <div class="v-messages theme--light error--text">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            <h3>{{ $v.$error? 'Пожалуйста исправьте текст в соответствии с подсказками' : '' }}</h3>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card class="app-dialog-card-r card">
        <h2>
          Регистрация прошла успешно!
        </h2>
        <span>
          Письмо с паролем отправлено вам на почту.
        </span>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { v4 as uuid } from 'uuid';

import ErrorMessagesMixin from '../../../app/components/mixins/error-messages-mixin';
import phoneHelper from '../../../app/components/helpers/phone-helper';

import FieldPhoneNumber from './common/field-phone-number';

export default {
  name: 'registration-form',

  mixins: [ErrorMessagesMixin],

  components: {
    FieldPhoneNumber
  },

  validations() {
    return {
      model: {
        name: {
          required,
        },
        phone: {
          required,
          uniquePhone: () => {
            return this.isUniquePhone;
          }
        },
        email: {
          required,
          email,
          uniqueEmail: () => {
            return this.isUniqueEmail;
          }
        },
        organization_name: {
          required: requiredIf(function() {
            return this.employer;
          }),
          quotes(value) {
            if (value === '') return true;
            const quotes = /^[^"']*$/.test(value);
            if (!quotes) {
              return false;
            } else {
              return true;
            }
          },
          sameLanguage(value) {
            if (value === '') return true;
            const onlyRussian = /^[а-яА-ЯЁё\s\d-]+$/.test(value);
            if (!onlyRussian) {
              const onlyEnglish = /^[a-zA-Z\s\d]+$/.test(value);
              if (!onlyEnglish) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          },
          uniqueOrganization: () => {
            return this.isUniqueOrganization;
          }
        }
      },
      validationGroup: [
        'model.name', 
        'model.phone', 
        'model.email', 
        'model.organization_name'
      ]
    };
  },

  data() {
    return {
      dialog: false,
      employer: true,
      model: {
        id: uuid(),
        name: '',
        full_name: '',
        phone: '',
        email: '',
        description: '',
        region_id: window.localStorage.getItem('region_id'),
        roles: [],
        link: '',
        type: '',
        organization_name: '',
        password: null,
        blocked: false,

        whatsapp: false,
        telegram: false,
      },
      currentPhoneHelper: phoneHelper,
      isUniquePhone: true,
      isUniqueEmail: true,
      isUniqueOrganization: true
    }
  },

  watch: {
    'model.phone'() {
      this.isUniquePhone = true;
    },
    'model.email'() {
      this.isUniqueEmail = true;
    },
    'model.organization_name'() {
      this.isUniqueOrganization = true;
    },
    dialog(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.$navigate('/login/');
      }
    }
  },

  mounted() {
    // this.employer = window.localStorage.getItem('employer');
    if (this.employer) {
      this.model.type = 'employer';
    } else {
      this.model.type = 'applicant';
    }
  },

  methods: {
    checkWhitespaces(value) {
      this.model.organization_name = value.replace(/^ +| +$|( ) +/g,"$1");
    },
    async registration() {
      try {
        if (this.employer) {
          const params = {
            id: this.model.id,
            name: this.model.organization_name,
            short_name: this.model.organization_name,
            type: this.model.type,
            link: this.model.link,
            organization_group_id: '8854e65a-c56b-4034-b961-e985f3dc3b94',
            organization_tariff_id: '8b54e65a-c561-4034-b951-e9d5f3dc3b77'
          };

          await this.$http.post(`/api/v1/organizations`, params);

          this.model.roles = [
            {
              role_id: 'a1190e29-95eb-4821-a20b-cd22f7bd9702',
              organization_id: this.model.id
            },
            {
              role_id: '6372fe39-2f34-4376-b9ec-75b32d02d567',
              organization_id: this.model.id
            }
          ];
        } else {
          // const params = {
          //   id: this.model.id,
          //   name: this.model.name,
          //   short_name: this.model.name,
          //   type: this.model.type
          // };

          // await this.$http.post(`/api/v1/organizations`, params);

          // this.model.roles = [
          //   {
          //     role_id: 'e4893273-cc4c-463c-bfd0-3807de3dfe10',
          //     organization_id: this.model.id
          //   }
          // ];
        }

        this.model.password = this.generatePassword();
        this.model.full_name = this.model.name;

        const utm = JSON.parse(window.localStorage.getItem('utm'));
        if (utm && utm.hasOwnProperty('utm_source') && utm.hasOwnProperty('utm_ts')) {
          this.model.utm_source = utm.utm_source;
          this.model.utm_ts = utm.utm_ts;
        }

        await this.$http.post(`/api/v1/users`, this.model);

        window.localStorage.removeItem('utm');

        this.dialog = true;
      } catch (error) {
        this.$setError(error);
      }  
    },
    generatePassword() {
      let charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      if (window.crypto && window.crypto.getRandomValues) {
        return Array(8)
          .fill(charset)
          .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * (x.length + 1))])
          .join('');    
      } else {
        let res = '';
        for (let i = 0, n = charset.length; i < 8; ++i) {
          res += charset.charAt(Math.floor(Math.random() * n));
        }
        return res;
      }
    },
    async check() {
      const isValid = await this.validate();
      if (!isValid) {
        return;
      }

      const phone_params = `{"page":0,"count":10,"text":"${this.model.phone}"}`;
      const phones = (await this.$http.get(`/api/v1/users/search/${phone_params}`)).data;
      if (phones && phones.length) {
        this.isUniquePhone = false;
      } else {
        this.isUniquePhone = true;
      }

      const email_params = `{"page":0,"count":10,"text":"${this.model.email}"}`;
      const emails = (await this.$http.get(`/api/v1/users/search/${email_params}`)).data;
      if (emails && emails.length) {
        this.isUniqueEmail = false;
      } else {
        this.isUniqueEmail = true;
      }

      const organization_params = `{"page":0,"count":10,"text":"${this.model.organization_name.toString()}"}`;
      const organizations = (await this.$http.get(`/api/v1/organizations/search/${organization_params}`)).data;
      if (organizations && organizations.length) {
        this.isUniqueOrganization = false;
      } else {
        this.isUniqueOrganization = true;
      }

      if (this.isUniquePhone && this.isUniqueEmail && this.isUniqueOrganization) {
        this.registration();
      }
    }
  }
}
</script>

<style lang="less">
.app-registration-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .v-text-field--outline {
    .v-input__control {
      .v-input__slot {
        margin-top: 0;
        min-height: 36px;
        border-width: 1px !important;
        border-radius: 4px;

        .v-text-field__slot {
          input {
            margin: auto;
          }
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }
  }

  .app-registration-form-title {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .app-registration-form-main {
    .vacancy__title {
      .title__input {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .app-registration-form {
	  align-items: center;

    .app-registration-form-main {
      width: 30%;
      min-width: 20em;
    }
  }  
}

.app-dialog-card-r {
	padding: 40px 32px;
	width: 400px;
	background: white;
	border-radius: 16px;
	overflow: hidden;
  text-align: center;

	.app-dialog-card__title {
		margin-bottom: 10px; 
	}
}
  
</style>